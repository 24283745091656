<script setup lang="ts">
import { routes, HOME } from "~/constants";
import { PartnerGroup } from "~/models/Partner";
import { VerbatimGroupType } from "~/models/Verbatim";
import { generateSoftwareApplicationJsonLd } from "~/json-ld/sofwareApplication";
import { generateOrganizationJsonLd } from "~/json-ld/organization";
import { generateFaqClassicJsonLd } from "~/json-ld/faq";

const appConfig = useAppConfig();
const popupVideo = useDialog();
const { t } = useI18n();
const { $api } = useNuxtApp();

const { data: partners, pending: pendingPartners } = await $api.partners.listByGroupSlug(
  {
    slug: PartnerGroup.COMMON,
  },
  {
    transform(input) {
      return input.data.map((partner) => ({
        src: partner.logo,
        alt: partner.alt,
        url: partner.url ?? undefined,
      }));
    },
  },
);

const { data: verbatimsEtab, pending: verbatimEtabPending } = await $api.verbatims.list({
  filters: {
    verbatim_group: {
      type: VerbatimGroupType.ETAB,
    },
  },
});

const { data: verbatimsCandidate, pending: verbatimCandidatePending } =
  await $api.verbatims.list({
    filters: {
      verbatim_group: {
        type: VerbatimGroupType.CANDIDATE,
      },
    },
  });

const { data: presses, pending: pressesPending } = await $api.presses.list(
  {},
  {
    transform(input) {
      return input.data.map((press) => ({
        src: press.logo.url,
        alt: press.logo_alt,
        url: press.url ?? undefined,
      }));
    },
  },
);
const openVideo = () => {
  popupVideo.open();
};

const faqItems = [...appConfig.faqHome.candidat, ...appConfig.faqHome.recruteur];

useHead({
  title: t("HOME.META.@TITLE"),
  meta: [
    {
      name: "description",
      content: t("HOME.META.@DESC"),
    },
    {
      name: "og:title",
      content: t("HOME.META.@TITLE"),
    },
    {
      name: "og:description",
      content: t("HOME.META.@DESC"),
    },
    {
      name: "og:type",
      content: "website",
    },
    {
      name: "facebook-domain-verification",
      content: "17fcdzjpkr3h6vc74p9y8x1e31bpu0",
    },
    {
      name: "facebook-domain-verification",
      content: "2dyy7wj2mnatz7x3mcp1dg2edefioy",
    },
  ],
  link: [
    {
      rel: "preload",
      href: "/img/candidat.webp",
      as: "image",
      fetchpriority: "high",
    },
    {
      rel: "preload",
      href: "/img/recruteur.webp",
      as: "image",
      fetchpriority: "high",
    },
  ],
  script: [
    {
      hid: "application-json-ld",
      innerHTML: generateSoftwareApplicationJsonLd,
      type: "application/ld+json",
    },
    {
      hid: "organization-json-ld",
      innerHTML: generateOrganizationJsonLd,
      type: "application/ld+json",
    },
    {
      hid: "faq-json-ld",
      innerHTML: generateFaqClassicJsonLd(faqItems),
      type: "application/ld+json",
    },
  ],
});
</script>
<template>
  <NuxtLayout class="home-layout">
    <!-- HUB CLIENT -->
    <HubClients />

    <CompanyRating />

    <!-- CAROUSEL PARTNERS -->
    <PartnersSlider
      v-if="partners && !pendingPartners"
      :items-slider="partners"
      type="recruteurs"
      :title-part1="$t('COMPONENTS.PARTNERS.ETAB.@TITLE_PART_1')"
      :title-part2="$t('COMPONENTS.PARTNERS.ETAB.@TITLE_PART_2')"
      :title-part3="$t('COMPONENTS.PARTNERS.ETAB.@TITLE_PART_3')"
      :subtitle="$t('COMPONENTS.PARTNERS.ETAB.@SUBTITLE')"
    />

    <!-- PUSH CLIENT RECRUTEURS -->
    <div class="row spacer-desktop">
      <div class="col-xs-12 col-md-8 col-no-gutter">
        <PushClients
          v-cloak
          client-type="recruteur"
          img-desktop="/img/recruteur-banner-desktop.webp"
          img-mobile="/img/recruteur-banner-mobile.webp"
          :link-url="routes.recruteurUrl.index"
          type="recruteur-dark"
          :label="$t('COMPONENTS.PUSH_CLIENTS.ETAB.@LABEL')"
          :title="$t('COMPONENTS.PUSH_CLIENTS.ETAB.@TITLE')"
          :description="$t('COMPONENTS.PUSH_CLIENTS.ETAB.@TEXT')"
        />
      </div>
      <div class="col-xs-12 col-md-4 col-no-gutter">
        <PushWebApp source-origin="home" />
      </div>
    </div>
    <!-- VERBATIM RECRUTEURS -->
    <div class="row">
      <Verbatims
        v-if="verbatimsEtab && !verbatimEtabPending"
        :title="$t('COMPONENTS.VERBATIMS.ETAB.@TITLE')"
        type="recruteur"
        :items-slider="verbatimsEtab?.data ?? []"
        lazy="true"
      />
    </div>
    <TitleSubtitleWithStars
      :title-part1="$t('COMPONENTS.PARTNERS.CANDIDATES.@TITLE_PART_1')"
      :title-part2="$t('COMPONENTS.PARTNERS.CANDIDATES.@TITLE_PART_2')"
      :title-part3="$t('COMPONENTS.PARTNERS.CANDIDATES.@TITLE_PART_3')"
      :subtitle="$t('COMPONENTS.PARTNERS.CANDIDATES.@SUBTITLE')"
      type="candidats"
      lazy="true"
    />
    <!-- PUSH CLIENT CANDIDAT -->
    <div class="row column-reverse push-candidat">
      <div class="col-xs-12 col-md-4 col-no-gutter">
        <PushMobileApps
          client-type="candidat"
          picto="/img/picto-app-candidat.png"
          :title="$t('COMPONENTS.DOWNLOAD_APP.CANDIDATES.@TITLE_2')"
          :apple-store-link="appConfig.applications.appleStore.candidates"
          :play-store-link="appConfig.applications.playStore.candidates"
          source-origin="home"
          lazy="true"
        />
      </div>
      <div class="col-xs-12 col-md-8 col-no-gutter">
        <PushClients
          client-type="candidat"
          img-desktop="/img/candidat-banner-desktop.webp"
          img-mobile="/img/candidat-banner-mobile.jpg"
          :link-url="routes.candidatUrl.index"
          type="candidat-dark"
          :label="$t('COMPONENTS.PUSH_CLIENTS.CANDIDATES.@LABEL')"
          :title="$t('COMPONENTS.PUSH_CLIENTS.CANDIDATES.@TITLE')"
          :description="$t('COMPONENTS.PUSH_CLIENTS.CANDIDATES.@TEXT')"
          lazy="true"
        />
      </div>
    </div>
    <!-- VERBATIM CANDIDAT -->
    <div class="row">
      <Verbatims
        v-if="!verbatimCandidatePending"
        :title="$t('COMPONENTS.VERBATIMS.CANDIDATE.@TITLE')"
        type="candidat"
        :items-slider="verbatimsCandidate?.data ?? []"
        lazy="true"
      />
    </div>
    <!-- PUSH RESSOURCES -->
    <div class="push-ressources-wrapper">
      <div class="row push-ressources">
        <div
          v-for="(ressource, index) in HOME['@HOME_PUSH_RESSOURCES']"
          :key="index"
          class="col-xs-12 push-ressources-item"
          :class="['col-md-' + 12 / HOME['@HOME_PUSH_RESSOURCES'].length]"
        >
          <PushRessources
            :resssource-name="ressource.name"
            :img-desktop="ressource.imgDesktop"
            :img-mobile="ressource.imgMobile"
            :url="ressource.url"
            :label-btn="ressource.label"
            :description="ressource.description"
            :external="ressource.external"
            :idx="index"
            lazy="true"
          />
        </div>
      </div>
    </div>
    <!-- JOIN US -->
    <EditoWithImage
      :title="$t('HOME.OUR_TEAM.@TITLE')"
      :description="$t('HOME.OUR_TEAM.@TEXT')"
      :img-desktop="appConfig.ourTeam.imgDesktop"
      :img-mobile="appConfig.ourTeam.imgMobile"
      :img-alt="appConfig.ourTeam.imgAlt"
      :fullwidth="true"
      lazy="true"
    />
    <div class="row last-block">
      <!-- CAROUSEL PARTNERS PRESS -->
      <PressSlider
        v-if="presses && !pressesPending"
        :items-slider="presses ?? []"
        lazy="true"
      />
      <div class="col-xs-12 col-md-6">
        <h3>
          {{ $t("COMPONENTS.PRESS.@TEXT") }}
        </h3>
      </div>
      <ClientOnly>
        <Teleport to="#portal-popup">
          <PopupVideo
            src="https://www.youtube.com/embed/9vkG9WmfvXs"
            :iframe="true"
            :open="popupVideo.isOpen"
            @close="popupVideo.close"
          />
        </Teleport>
      </ClientOnly>
      <div class="col-xs-12 col-md-6 poster-youtube">
        <NuxtImg
          class="poster-youtube-image"
          src="/img/video_youtube_poster.webp"
          alt="Video Youtube Extracadabra"
          title="Video Youtube Extracadabra"
          loading="lazy"
          @click="openVideo"
        />
      </div>
    </div>
    <div class="faq last-block">
      <span class="faq__title">Des questions ?</span>
      <div class="row between-md">
        <FaqSmall
          type="candidat"
          title="Candidat"
          :faq-items="appConfig.faqHome.candidat"
        />
        <FaqSmall
          type="recruteur"
          title="Recruteur"
          :faq-items="appConfig.faqHome.recruteur"
        />
      </div>
    </div>
  </NuxtLayout>
</template>

<style lang="scss" scoped>
.faq {
  margin-bottom: var(--showcase-size-250);
  &__title {
    width: 100%;
    display: flex;
    font-size: var(--showcase-size-125);
    justify-content: center;
    color: var(--showcase-color-additional-dark);
    font-weight: 800;
    margin-bottom: var(--showcase-size-250);
    @include mq(desktop) {
      font-size: var(--showcase-size-175);
    }
  }
}

.col-no-gutter {
  @include mq(desktop) {
    padding: $gutter;
  }
}
.column-reverse {
  flex-direction: column-reverse;
  @include mq(desktop) {
    flex-direction: row;
  }
}
.push-candidat {
  margin-top: var(--showcase-size-100);
}
.push-ressources-wrapper {
  @include mq(desktop) {
    box-shadow: 0px 2px 40px 0px rgba(112, 112, 112, 0.12);
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 16px 0 24px;
    position: absolute;
    left: 0;
    padding: 40px 0;
    margin-top: 50px;
  }
  .push-ressources {
    margin: var(--showcase-size-100) 0 0;
    @include mq(desktop) {
      margin: var(--showcase-size-100) 0 $gutter * 3;
      max-width: $grid-max-width;
    }
    &:first-child {
      margin: 0;
    }
  }
}

.spacer-desktop {
  @include mq(desktop) {
    margin-top: var(--showcase-size-250);
  }
}
.last-block {
  margin-bottom: $gutter * 5;
  padding: 0 var(--showcase-size-100);
  h3 {
    font-weight: 800;
    color: $dark;
    font-size: 20px;
    text-align: center;
    display: flex;
    align-items: center;
    height: 100%;
    @include mq(desktop) {
      padding: 0 100px;
      line-height: 34px;
      font-size: 24px;
    }
  }
}

.poster-youtube {
  display: flex;
  justify-content: center;
  &:hover {
    cursor: pointer;
  }
  &-image {
    width: 100%;
  }
}
</style>
