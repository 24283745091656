<!-- eslint-disable vue/multi-word-component-names -->
<script setup lang="ts">
import appConfig from "~/app.config";

const { isDesktop } = useDevice();
</script>

<template>
  <div class="row company-rating">
    <div class="company-rating-wrapper col-xs-12 col-md-3">
      <div class="company-rating-container">
        <div class="company-rating-stars">
          <span class="company-rating-stars--left">{{
            appConfig.companyRating.rate
          }}</span>
          <div
            class="company-rating-stars--right"
            :data-rating="appConfig.companyRating.rate"
          >
            <div class="rating__empty"></div>
            <div class="rating__filled"></div>
          </div>
        </div>
        <div class="company-rating-brand">
          <img
            :src="appConfig.companyRating.logo.src"
            :alt="appConfig.companyRating.logo.alt"
          />
        </div>
      </div>
    </div>
    <div v-if="isDesktop" class="company-rating-wrapper col-md-9">
      <div class="company-rating-quote-container">
        <ul class="company-rating-quote">
          <li
            v-for="quote in appConfig.companyRating.quotes"
            :key="quote.blockquote"
            class="company-rating-quote-item"
          >
            <blockquote>
              {{ quote.blockquote }}
            </blockquote>
            <div class="company-rating-quote-item-bottom">
              <span class="company-rating-quote-item-author">{{ quote.author }}</span>
              <span class="company-rating-quote-item-rate"
                ><img src="/img/rate-filled.png" alt="avis google 5/5"
              /></span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.company-rating {
  @include mq(desktop) {
    justify-content: flex-start;
  }
  .company-rating-wrapper {
    padding: 0 var(--showcase-size-100);
    @include mq(desktop) {
      margin: var(--showcase-size-350) 0 var(--showcase-size-250);
      display: flex;
      padding: 0;
    }
    & + .company-rating-wrapper {
      margin-left: 0;
      justify-content: end;
    }
  }

  .company-rating-container,
  .company-rating-quote-container {
    background-color: #f2fafe;
    border-radius: 15px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: var(--showcase-size-150) var(--showcase-size-075) var(--showcase-size-150)
      var(--showcase-size-150);
    display: flex;
    justify-content: space-between;
    @include mq(desktop) {
      justify-content: center;
      max-width: 300px;
      flex-wrap: wrap;
      align-items: center;
      padding: var(--showcase-size-150) var(--showcase-size-575);
    }
  }
  .company-rating-quote-container {
    @include mq(desktop) {
      max-width: none;
      padding: var(--showcase-size-200) var(--showcase-size-200) var(--showcase-size-150);
      width: 96%;
      align-items: flex-start;
    }
  }

  &-stars {
    display: flex;
    align-items: center;
    @include mq(desktop) {
      justify-content: center;
      flex-wrap: wrap;
      align-items: center;
    }
    &--left {
      font-weight: 700;
      font-size: 32px;
      color: var(--showcase-color-additional-dark);
      position: relative;
      margin-right: var(--showcase-size-100);
      @include mq(desktop) {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        margin-bottom: var(--showcase-size-050);
      }
      &:before {
        content: "";
        display: block;
        width: 16px;
        height: 20px;
        position: absolute;
        top: -5px;
        left: -10px;
        background-image: url("/img/stars-black.png");
        background-size: contain;
        @include mq(desktop) {
          left: 12px;
        }
      }
    }
    &--right {
      position: relative;
      width: 120px; // Ajustez selon la taille de vos images
      height: 30px; // Ajustez selon la taille de vos images
      display: flex;
      align-items: center;
      @include mq(desktop) {
        margin-bottom: var(--showcase-size-050);
      }
      .rating {
        &__empty,
        &__filled {
          position: absolute;
          top: 4px;
          left: 0;
          width: 100%;
          height: 100%;
          background-repeat: no-repeat;
          background-size: contain;
        }

        &__empty {
          background-image: url("/img/rate-empty.png");
          z-index: 0;
        }

        &__filled {
          z-index: 1;
          width: 90%; // Cette valeur contrôle le remplissage (90% = 4.5 étoiles)
          overflow: hidden;
          &:before {
            content: "";
            display: block;
            width: 120px;
            height: 30px;
            background-image: url("/img/rate-filled.png");
            background-size: contain;
            overflow: hidden;
            background-repeat: no-repeat;
          }
        }
      }
    }
  }
  &-brand {
    @include mq(desktop) {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    img {
      max-width: 100px;
      @include mq(desktop) {
        max-width: 140px;
      }
    }
  }
  &-quote {
    display: flex;
    width: 100%;
    &-item {
      margin-right: var(--showcase-size-350);
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      blockquote {
        font-style: italic;
        line-height: 21px;
        font-size: 14px;
        margin-bottom: var(--showcase-size-075);
      }
      &-author {
        font-size: 14px;
      }
      &-rate {
        margin-left: var(--showcase-size-075);
        img {
          height: 15px;
        }
      }
      & + .company-rating-quote-item {
        margin-right: 0;
      }
      &-bottom {
        display: flex;
        align-self: end;
      }
    }
  }
}
</style>
